<template>
  <el-container class="container">
    <Header></Header>
    <el-container>
      <Menu></Menu>
      <el-main class="main" :style="isCollapse">
        <Breadcrumb></Breadcrumb>
        <div class="content">
          <router-view />
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from "@/components/header/header.vue";
import Menu from "@/components/menu/menu.vue";
import Breadcrumb from "@/components/breadcrumb/breadcrumb.vue";

export default {
  components: {
    Header,
    Menu,
    Breadcrumb,
  },
  data() {
    return {
      isCollapse: {
        marginLeft: "64px",
      },
    };
  },
  created() {
    this.bus.$on("isCollapse", (val) => {
      let obj = {
        marginLeft: val ? "64px" : "200px",
      };
      this.isCollapse = obj;
    });
  },
};
</script>
